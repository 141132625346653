import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useState } from 'react';
import { InboxContext } from '../lib/InboxContext';

export default function App(props: AppProps) {
    const { Component, pageProps } = props;
    const [inboxArray, setInbox] = useState([])

    return (
        <>
            <Head>
                <title>Chance Portal</title>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            </Head>

            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={{
                    /** Put your mantine theme override here */
                    colorScheme: 'dark',
                }}
            >
                <NotificationsProvider>
                    <InboxContext.Provider value={{ inboxArray, setInbox }}>
                        <Component {...pageProps} />
                    </InboxContext.Provider>
                </NotificationsProvider>
            </MantineProvider>
        </>
    );
}
